<template>
  <div
    class="headline"
    :class="{ 'headline--bold': bold, [`headline--${size}`]: size }"
  >
    <slot>This is a title</slot>
    <Hr v-if="hr" />
  </div>
</template>

<script>
import Hr from '@/components/partials/Hr.vue'

export default {
  name: 'Headline',

  components: { Hr },

  props: {
    bold: { type: Boolean, default: false },
    size: { type: String, default: null },
    hr: { type: Boolean, default: true },
  },
}
</script>

<style lang="scss" scoped>
.headline {
  width: 100%;

  &--large {
    font-size: 1.6rem;
  }

  &--extra-large {
    font-size: 2rem;
  }

  &--bold {
    font-weight: 700;
  }
}
</style>
